import { createGlobalStyle } from "styled-components";
// import { ThemeProvider } from "next-themes";

// Your theming variables
// const GlobalStyle = createGlobalStyle`
// 	h1 {
// 		color: red;
// 	}

//   :root {
// 		--bg: #FFF;
// 		--fg: #000;
//   }

//   [data-theme="dark"] {
// 		--bg: #000;
// 		--fg: #FFF;

// 		h1 {
// 			color: blue;
// 		}
//   }
// `;
const GlobalStyle = createGlobalStyle`
	html, body {
		padding: 0;
		margin: 0;
		height: 100%;
		min-height: 100vh;
		background-color: rgb(240, 240, 240);
		font-family: Calibri, sans-serif;
		/* font-family: Helvetica Neue, Helvetica, Arial, sans-serif; */
	}
	
	a {
		color: #007AFF;
		text-decoration: inherit;
	}
	
	a:hover {
		color: #00BBFF;
	}
	
	#__next {
		display: flex;
    flex-direction: column;
		min-height: 100vh;
	}
	
	footer {
    margin-top: auto;
	}
	
	img {
		image-rendering: crisp-edges;
	}
`;

// const theme = {
// 	name: "red",
// 	colors: {
// 		primary: "#0070f3"
// 	}
// };

export default function MyApp({ Component, pageProps }) {
	return (
		<>
			<GlobalStyle />
			{/* <ThemeProvider defaultTheme="system" theme={theme}> */}
			<Component style={{ height: "100%" }} {...pageProps} />
			{/* </ThemeProvider> */}
		</>
	);
}
